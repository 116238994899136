import React from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import { space } from 'styled-system'
import { Link } from 'gatsby'
import media from '../../styles/media'
import { Copy, H3 } from '../Text'
import Twitter from '../../images/twitter.svg'
import Youtube from '../../images/youtube.svg'
import Facebook from '../../images/facebook.svg'
import Logo from '../../images/logo.svg'

const Wrapper = styled.footer`
  background-color: ${props => props.theme.colors.background};
  color: black;
`

const Social = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3.2rem 0;
  border-bottom: 1px solid white;
`

const Share = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${media.up.lg`
    flex-direction: row;
  `}
`

const SocialIcons = styled.div`
  margin-top: 1.6rem;
  ${media.up.lg`
    margin-top: 0;
    margin-left: 1.6rem;
    `}
  a {
    &:not(:last-child) {
      margin-right: 1.5rem;
    }
  }
`

const Follow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 3.2rem;
  ${media.up.lg`
    flex-direction: row;
    margin-top: 0;
    margin-left: 6.4rem;
`}
`

const UpperFooter = styled.div`
  ${media.up.lg`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  `}
`

const BottomFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 3.3rem 0 4rem;
  ${media.up.lg`
    padding: 3.2rem 3.2rem 3.2rem;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  `}
`

const Links = styled.div`
  order: 1;
  a {
    &:not(:last-child) {
      margin-right: 2rem;
    }
  }
  ${media.up.lg`
  order: 2;
  `}
`

const LogoWrapper = styled.div`
  order: 2;
  margin-top: 3.2rem;
  ${media.up.lg`
  margin-top: 0;
  `}
`

const StyledCopy = styled(Copy)`
  color: #333333;
`

const tweetText = `Interhyp ist Deutschlands größter Vermittler für private Baufinanzierung. @interhyp
https://www.interhyp.de/`

const Footer = () => (
  <Wrapper>
    <Social>
      <Container>
        <UpperFooter>
          <Share>
            <StyledCopy>Teilen auf:</StyledCopy>
            <SocialIcons>
              <a
                href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
                  tweetText
                )}`}
                rel="nofollow"
              >
                <img src={Twitter} alt="" />
              </a>
              <a
                href={`https://www.facebook.com/sharer/sharer.php?u=${
                  typeof window !== 'undefined' ? window.location.href : ''
                }`}
                rel="nofollow"
              >
                <img src={Facebook} alt="" />
              </a>
            </SocialIcons>
          </Share>
          <Follow>
            <StyledCopy>Folge Interhyp auf:</StyledCopy>
            <SocialIcons>
              <a
                href="https://twitter.com/interhyp"
                rel="noopener noreferrer nofollow"
                target="_blank"
              >
                <img src={Twitter} alt="" />
              </a>
              <a
                href="https://www.facebook.com/Interhyp"
                rel="noopener noreferrer nofollow"
                target="_blank"
              >
                <img src={Facebook} alt="" />
              </a>
              <a
                href="https://www.youtube.com/user/Interhyp"
                rel="noopener noreferrer nofollow"
                target="_blank"
              >
                <img src={Youtube} alt="" />
              </a>
            </SocialIcons>
          </Follow>
        </UpperFooter>
      </Container>
    </Social>
    <BottomFooter>
      <LogoWrapper>
        <Link to="/" target="_blank">
          <img src={Logo} alt="Logo" />
        </Link>
      </LogoWrapper>
      <Links>
        <a
          href="https://www.interhyp.de/ueber-interhyp/impressum/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <StyledCopy>Impressum</StyledCopy>
        </a>
        <Link to="/datenschutz" target="_blank">
          <StyledCopy>Datenschutz</StyledCopy>
        </Link>

        <StyledCopy
          css="cursor: pointer"
          onClick={() => {
            ;(function() {
              document.dispatchEvent(
                new CustomEvent('ihgConsentManagerModalAction', {
                  detail: 'show',
                })
              )
            })()
          }}
        >
          Cookie-Einstellungen ändern
        </StyledCopy>
      </Links>
    </BottomFooter>
  </Wrapper>
)

export default Footer
