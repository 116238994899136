import React from 'react'
import styled from 'styled-components'
import Layout from '../components/CleanLayout'
import SEO from '../components/seo'
import { H1, H2, H3, Copy } from '../components/Text'

const Content = styled.div`
  word-break: break-word;
  hyphens: auto;
  width: 100%;
  a {
    text-decoration: underline;
  }
`

const Datenschutz = () => (
  <Layout>
    <SEO title="Datenschutz" />
    <article className="article">
      <H1>Datenschutzerklärung</H1>
      <div>&nbsp;</div>
    </article>
    <Content>
      <div>
        <p style={{ paddingBottom: '1em' }}>
          <strong>1. Gliederung</strong>
        </p>
        <ol type="A" style={{ paddingBottom: '4em' }}>
          <li>
            <a href="#1">Datenschutzinformationen</a>
          </li>
          <li>
            <a href="#cookie">Cookie Informationen </a>
          </li>
          <li>
            <a href="#3">Links auf Internetseiten Dritter</a>
          </li>
          <li>
            <a href="#4">Soziale Netzwerke, Plugins und Tools</a>
          </li>
          <li>
            <a href="#5">Aktualisierung dieser Datenschutzerklärung </a>
          </li>
        </ol>
        <p style={{ paddingBottom: '1em' }}>
          <strong>2. Inhalte zu o.g. Gliederung</strong>
        </p>
        <p style={{ paddingBottom: '2em' }}>
          <strong>Datenschutz</strong>
          <br />
          <br />
          Datensicherheit und Datenschutz gehören in unserem Geschäft zur
          Tagesordnung. Schließlich vertrauen Sie uns eine Vielzahl
          vertraulicher Informationen an und möchten nicht, dass diese in die
          falschen Hände geraten. Als seriöser und zuverlässiger Partner ist es
          unsere Pflicht, sowohl die Sicherheit der Datenübertragung als auch
          den vollkommen vertraulichen Umgang mit Ihren personenbezogenen Daten
          sicherzustellen. Sie finden im Folgenden ausführliche Informationen
          dazu, wie wir Ihre Daten verarbeiten, wenn Sie auf unserer Website
          unterwegs sind oder sich für eine persönliche Finanzierungsberatung
          bei uns entscheiden.
        </p>
        <ol type="A">
          <li id={1}>
            <strong>Datenschutzinformationen</strong>
            <br />
            <br />
            <p style={{ paddingBottom: '2em' }}>
              <strong>Datenschutzinformationen der Interhyp AG</strong>
            </p>
            <p style={{ paddingBottom: '2em' }}>
              <strong>
                Datenschutzinformationen der Interhyp AG - Kurzfassung
              </strong>
              <br />
              <br />
              Die Interhyp AG nimmt den Schutz Ihrer personenbezogenen Daten
              sehr ernst.
              <br />
              <br />
              Wir verarbeiten Ihre personenbezogenen Daten, um Ihnen unsere
              Website zur Verfügung zu stellen. Dies tun wir nur für diesen
              Zweck und nur im gesetzlich zulässigen Umfang auf Basis unseres
              berechtigten Interesses.
              <br />
              <br />
              Wenn Sie weitere Informationen über die Verarbeitung Ihrer
              personenbezogenen Daten sowie über Ihre diesbezüglichen Rechte
              erhalten möchten, lesen Sie bitte unten die Langversion unserer
              Datenschutzinformationen. Dort finden Sie auch unsere
              Kontaktinformationen.
              <br />
              <br />
              Möchten Sie weitere Informationen über die auf unserer Website
              verwendeten Cookies erhalten, lesen Sie bitte unsere{' '}
              <a href="#cookie">Cookie Informationen</a>.
            </p>
            <p style={{ paddingBottom: '2em' }}>
              <strong>
                Datenschutzinformationen der Interhyp AG - Langfassung
              </strong>
              <br />
              <br />
              Die folgenden Informationen erläutern Ihnen, unseren
              Websitebesuchern, wie wir, die Interhyp AG, Ihre personenbezogenen
              Daten verarbeiten. Sie erfahren, zu welchen Zwecken und auf
              welcher Rechtsgrundlage wir Ihre personenbezogenen Daten
              verarbeiten, wie lange wir diese speichern und welche Rechte Sie
              in Bezug auf Ihre Daten haben. Außerdem informieren wir Sie
              darüber, mit wem wir Ihre Daten teilen und wie Sie uns bei Fragen
              kontaktieren können.
            </p>
            <ol type={1} start={1} style={{ paddingBottom: '2em' }}>
              <li style={{ paddingBottom: '1em' }}>
                <strong>
                  Wer ist für die Datenverarbeitung verantwortlich und an wen
                  können Sie sich wenden? Wie erreichen Sie den
                  Datenschutzbeauftragten?
                </strong>
              </li>
              <li style={{ listStyleType: 'none' }}>
                Verantwortliche Stelle ist:
                <br />
                <br />
                Interhyp AG
                <br />
                Domagkstraße 34
                <br />
                80807 München
                <br />
                Telefon: 0800- 200 15 15 15
                <br />
                Fax: 089- 20 30 7 51000
                <br />
                E-Mail:{' '}
                <a href="mailto:info@interhyp.de" target="_blank">
                  info@interhyp.de
                </a>
                <br />
                <br />
                Die Interhyp AG wird gesetzlich vertreten durch den Vorstand:
                Jörg Utecht (Vorsitzender), Marcus Fienhold, Stefan Hillbrand,
                Mirjam Mohr
                <br />
                <br />
                Sie erreichen unseren betrieblichen Datenschutzbeauftragten
                unter:
                <br />
                <br />
                Interhyp AG
                <br />
                Datenschutzbeauftragter
                <br />
                Domagkstraße 34
                <br />
                80807 München
                <br />
                E-Mail:{' '}
                <a href="mailto:datenschutz@interhyp.de" target="_blank">
                  datenschutz@interhyp.de
                </a>
                <br />
                <br />
                „Personenbezogene Daten“ im Sinne dieser
                Datenschutzinformationen sind Informationen, die sich auf eine
                identifizierte oder identifizierbare natürliche Person beziehen.
                <br />
                <br />
                „Verarbeiten“ ist jeder mit oder ohne Hilfe automatisierter
                Verfahren ausgeführter Vorgang im Zusammenhang mit
                personenbezogenen Daten wie z.B. das Erheben, die Speicherung,
                die Anpassung oder Veränderung, die Verwendung oder die
                Offenlegung durch Übermittlung.
              </li>
            </ol>
            <ol type={1} start={2} style={{ paddingBottom: '2em' }}>
              <li style={{ paddingBottom: '1em' }}>
                <strong>
                  Wie verarbeiten wir personenbezogene Daten und aus welchen
                  Quellen stammen sie?
                </strong>
              </li>
              <li style={{ listStyleType: 'none' }}>
                Wir verarbeiten personenbezogene Daten, die wir von Ihnen
                erhalten.
                <br />
                <br />
                Konkret verarbeiten wir folgende personenbezogene Daten für
                folgende Zwecke:
                <br />
                <br />
                <strong>Beim Besuch unserer Website</strong>
                <br />
                <br />
                Wir nutzen auf unserer Website unter{' '}
                <a
                  href="http://www.schuldenfrei-tag.interhyp.de"
                  target="_blank"
                >
                  http://www.schuldenfrei-tag.interhyp.de
                </a>{' '}
                so genannte Cookies und ähnliche Technologien zur Verfolgung
                Ihres Nutzungsverhaltens (nachfolgend zusammen „Cookies“). Was
                Cookies sind, wie Sie unsere Nutzung von Cookies steuern können
                und welche Cookies wir wie nutzen, erfahren Sie in unseren{' '}
                <a href="#cookie">Cookie Informationen</a>.<br />
                <br />
                Wir verarbeiten außerdem die sogenannten Logfiles unseres
                Webservers. Diese Weblogs enthalten Informationen zu Ihrer
                Nutzung der Website (Nutzungsdaten), u. a. Zugriffszeit, Name
                der abgerufenen Website bzw. -datei, übertragene Datenmenge,
                Status des Abrufs, Browsertyp und –version, Betriebssystemtyp
                und –version und IP-Adresse. Wir verarbeiten diese
                personenbezogenen Daten zum Betrieb der Website und zur
                Gewährleistung der IT-Sicherheit.
                <br />
                <br />
                Wir verarbeiten diese personenbezogenen Daten auf Basis von Art.
                6 Abs. 1 lit. f DSGVO (Interessenabwägung), da wir ein Interesse
                daran haben, die Website funktionstüchtig und sicher zur
                Verfügung zu stellen. Dies entspricht auch Ihren Interessen, die
                Seite optimal nutzen zu können, sodass Ihre Interessen der
                Verarbeitung nicht entgegenstehen.
                <br />
                <br />
                Wir löschen Ihre Nutzungsdaten, wenn sie nicht mehr zu Zwecken
                der IT-Sicherheit benötigt werden. Darüber hinaus speichern wir
                Ihre Nutzungsdaten nur noch zur Erfüllung etwaiger gesetzlicher
                Aufbewahrungsfristen.
              </li>
            </ol>
            <ol type={1} start={3} style={{ paddingBottom: '2em' }}>
              <li style={{ paddingBottom: '2em' }}>
                <strong>Wer bekommt Ihre Daten?</strong>
              </li>
              <ol type="a">
                <li style={{ paddingBottom: '2em' }}>
                  <strong>Innerhalb der Interhyp</strong>
                  <br />
                  <br />
                  Innerhalb der Interhyp erhalten diejenigen Stellen Zugriff auf
                  Ihre Daten, die diese zur Erfüllung unserer Pflichten
                  brauchen. Diese Übermittlung erfolgt auf Basis einer
                  Interessenabwägung (Art. 6 Abs. 1 lit. f DSGVO).
                </li>
                <li style={{ paddingBottom: '2em' }}>
                  <strong>Unsere Dienstleister</strong>
                  <br />
                  <br />
                  Wir übermitteln personenbezogene Daten an von uns eingesetzte
                  Dienstleister und Erfüllungsgehilfen, die uns bei den oben
                  genannten Zwecken als Auftragsverarbeiter unterstützen. Dies
                  sind Unternehmen in den Bereichen IT-Dienstleistungen,
                  Logistik, Druckdienstleistungen, Telekommunikation, Beratung
                  und Consulting sowie Vertrieb und Marketing.
                  <br />
                  <br />
                  Die Übermittlung erfolgt auf Basis einer Interessenabwägung
                  (Art. 6 Abs. 1 lit. f DSGVO). Unser Interesse besteht hier
                  vorwiegend darin, effektive und spezialisierte Dienstleister
                  einzusetzen, die entsprechend Art. 28 DSGVO strikt unseren
                  Anweisungen unterworfen sind.
                </li>
                <li>
                  <strong>Dritte</strong>
                  <br />
                  <br />
                  Die Weitergabe Ihrer Daten an andere Dritte erfolgt nur,
                  soweit in dieser Datenschutzerklärung ausdrücklich darauf
                  hingewiesen wird oder wir dazu gesetzlich verpflichtet sind.
                  <br />
                  <br />
                  Außerdem übermitteln wir Ihre personenbezogenen Daten an
                  Behörden, Gerichte oder Organisationen, soweit dies
                  erforderlich ist zur Wahrung berechtigter Interessen von uns
                  oder Dritten. Gründe hierfür können die Geltendmachung
                  rechtlicher Ansprüche und die Verteidigung bei rechtlichen
                  Streitigkeiten, die Gewährleistung der IT-Sicherheit und des
                  IT-Betriebs der Interhyp oder die Verhinderung und Aufklärung
                  von Straftaten sein. Wir informieren Sie im Falle einer
                  derartigen Übermittlung gesondert entsprechend den
                  datenschutzrechtlichen Vorgaben.
                </li>
              </ol>
            </ol>
            <ol type={1} start={4} style={{ paddingBottom: '2em' }}>
              <li style={{ paddingBottom: '1em' }}>
                <strong>Überblick über unsere Datenverarbeitungen</strong>
              </li>
              <li style={{ listStyleType: 'none', paddingBottom: '2em' }}>
                Im Folgenden haben wir für Sie noch einmal vereinfacht
                beschrieben, wie wir Ihre Daten verarbeiten:
              </li>
              <li style={{ listStyleType: 'none', width: '100%' }}>
                <div style={{ overflow: 'scroll', width: '100%' }}>
                  <table
                    cellSpacing={0}
                    cellPadding={10}
                    border={1}
                    style={{
                      width: '70em',
                      hyphens: 'auto',
                      wordBreak: 'break-word',
                    }}
                  >
                    <tbody>
                      <tr>
                        <td valign="middle">
                          <strong>Zweck</strong>
                        </td>
                        <td valign="middle">
                          <strong>Datenkategorien</strong>
                        </td>
                        <td valign="middle">
                          <strong>Rechtsgrundlage</strong>
                        </td>
                        <td valign="middle">
                          <strong>Empfänger</strong>
                        </td>
                      </tr>
                      <tr>
                        <td valign="top">Websitebesuch</td>
                        <td valign="top">Nutzungsdaten aus Logfiles</td>
                        <td valign="top">
                          Interessenabwägung; bei Cookies: ggf. Einwilligung
                        </td>
                        <td valign="top">Auftragsverarbeiter</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </li>
            </ol>
            <ol type={1} start={5} style={{ paddingBottom: '2em' }}>
              <li style={{ paddingBottom: '1em' }}>
                <strong>
                  Werden Daten in ein Drittland oder an eine internationale
                  Organisation übermittelt?
                </strong>
              </li>
              <li style={{ listStyleType: 'none' }}>
                Eine Datenübermittlung an Stellen in Staaten außerhalb der
                Europäischen Union (sogenannte Drittstaaten) findet nicht statt.
                Ausnahme hiervon ist eine Datenübermittlung in den Fällen, in
                denen wir Cookies nicht-europäischer Anbieter einsetzen. Hier
                stellen wir ein angemessenes Datenschutzniveau durch
                entsprechende Garantien sicher, soweit dies gesetzlich
                erforderlich ist, z. B. EU-Standardvertragsklauseln oder BCR-Zertifizierung. Wenn Sie weitere Informationen
                zu den von uns eingesetzten Garantien erhalten möchten,
                kontaktieren Sie uns bitte unter{' '}
                <a href="mailto:info@interhyp.de" target="_blank">
                  info@interhyp.de
                </a>
                .<br />
                <br />
                Weitere Informationen zu Cookies finden Sie in unseren{' '}
                <a href="#cookie">Cookie Informationen.</a>
              </li>
            </ol>
            <ol type={1} start={6} style={{ paddingBottom: '1em' }}>
              <li style={{ paddingBottom: '1em' }}>
                <strong>Welche Datenschutzrechte haben Sie?</strong>
              </li>
              <li style={{ listStyleType: 'none', paddingBottom: '2em' }}>
                Als betroffene Person einer Datenverarbeitung haben Sie die
                folgenden Rechte (im Folgenden auch „Betroffenenrechte“
                genannt), sofern die entsprechenden Voraussetzungen vorliegen.
                Bitte kontaktieren Sie uns, wenn Sie diese Rechte geltend machen
                möchten. Beim Auskunftsrecht und beim Löschungsrecht gelten die
                Einschränkungen nach §§ 34 und 35 BDSG.
              </li>
              <ol type="a">
                <li style={{ paddingBottom: '2em' }}>
                  <strong>Recht auf Auskunft (Art. 15 DSGVO)</strong>
                  <br />
                  <br />
                  Sie haben uns gegenüber das Recht, Auskunft darüber zu
                  erhalten, welche Daten wir zu Ihrer Person verarbeiten.
                  Insbesondere können Sie Auskunft über die Verarbeitungszwecke,
                  die Kategorien der verarbeiteten Daten, etwaigen Empfängern
                  der Daten und der geplanten Speicherdauer verlangen.
                </li>
                <li style={{ paddingBottom: '2em' }}>
                  <strong>Recht auf Berichtigung (Art. 16 DSGVO)</strong>
                  <br />
                  <br />
                  Sollten die Sie betreffenden Daten nicht richtig oder
                  unvollständig sein, so können Sie die Berichtigung unrichtiger
                  oder die Vervollständigung unvollständiger Angaben verlangen.
                </li>
                <li style={{ paddingBottom: '2em' }}>
                  <strong>
                    Recht auf Löschung personenbezogener Daten (Art. 17 DSGVO)
                  </strong>
                  <br />
                  <br />
                  Sie können die Löschung Ihrer personenbezogenen Daten
                  verlangen, soweit die Daten insbesondere für den vorgesehenen
                  Zweck nicht mehr erforderlich sind bzw. unrechtmäßig
                  verarbeitet werden oder Sie Ihre Einwilligung widerrufen oder
                  einen Widerspruch erklärt haben.
                </li>
                <li style={{ paddingBottom: '2em' }}>
                  <strong>
                    Recht auf Einschränkung der Datenverarbeitung (Art. 18
                    DSGVO)
                  </strong>
                  <br />
                  <br />
                  Sie können die Einschränkung der Verarbeitung Ihrer
                  personenbezogenen Daten verlangen, solange und soweit die
                  Richtigkeit der Daten von Ihnen bestritten wird, die
                  Verarbeitung unrechtmäßig ist, Sie aber deren Löschung
                  ablehnen oder wir die Daten nicht mehr benötigen, Sie jedoch
                  diese zur Geltendmachung, Ausübung oder Verteidigung von
                  Rechtsansprüchen benötigen oder Sie gemäß Art. 21 DSGVO
                  Widerspruch gegen die Verarbeitung eingelegt haben.
                </li>
                <li style={{ paddingBottom: '2em' }}>
                  <strong>
                    Recht auf Datenübertragbarkeit (Art. 20 DSGVO)
                  </strong>
                  <br />
                  <br />
                  Sie können Ihre personenbezogenen Daten, die Sie uns
                  bereitgestellt haben, in einem strukturierten, gängigen und
                  maschinenlesebaren Format erhalten. Das Recht auf
                  Datenübertragung beinhaltet das Recht zur Übermittlung der
                  Daten an einen anderen Verantwortlichen; auf Verlangen werden
                  wir – soweit technisch möglich – Daten daher direkt an einen
                  von Ihnen benannten oder noch zu benennenden Verantwortlichen
                  übermitteln.
                </li>
                <li style={{ paddingBottom: '2em' }}>
                  <strong>
                    Recht zum Widerspruch gegen bestimmte Datenverarbeitungen
                    (Art. 21 DSGVO)
                  </strong>
                  <br />
                  <br />
                  Im Fall einer Verarbeitung personenbezogener Daten zur
                  Wahrnehmung berechtigter Interessen (Art. 6 Abs. 1 lit. f
                  DSGVO)) oder im Falle der Direktwerbung, können Sie der
                  Verarbeitung der Sie betreffenden personenbezogenen Daten
                  jederzeit mit Wirkung für die Zukunft widersprechen.
                  <br />
                  <br />
                  Im Fall des Widerspruchs haben wir jede weitere Verarbeitung
                  Ihrer Daten zu den vorgenannten Zwecken zu unterlassen.
                </li>
                <li style={{ paddingBottom: '2em' }}>
                  <strong>
                    Recht auf Widerruf der datenschutzrechtlichen
                    Einwilligungserklärung
                  </strong>
                  <br />
                  <br />
                  Sie haben das Recht, Ihre datenschutzrechtliche
                  Einwilligungserklärung jederzeit mit Wirkung für die Zukunft
                  zu widerrufen. Durch den Widerruf der Einwilligung wird die
                  Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf
                  erfolgten Verarbeitung nicht berührt.
                </li>
                <li style={{ paddingBottom: '2em' }}>
                  <strong>
                    Recht auf Beschwerde bei einer Aufsichtsbehörde
                  </strong>
                  <br />
                  <br />
                  Wenn Sie der Ansicht sind, dass die Verarbeitung der Sie
                  betreffenden personenbezogenen Daten durch uns gegen die DSGVO
                  verstößt, haben Sie das Recht auf Beschwerde beim
                  Datenschutzbeauftragten sowie bei einer Aufsichtsbehörde,
                  insbesondere in dem Mitgliedstaat Ihres gewöhnlichen
                  Aufenthaltsorts, Ihres Arbeitsplatzes oder des Orts des
                  mutmaßlichen Verstoßes.
                </li>
                <li style={{ paddingBottom: '2em' }}>
                  <strong>Weitere Informationen zu Ihren Rechten</strong>
                  <br />
                  <br />
                  Weitere Informationen zu Ihren Rechten in Bezug auf Ihre
                  personenbezogenen Daten finden Sie bspw. bei der Europäischen
                  Kommission unter{' '}
                  <a
                    href="https://ec.europa.eu/info/law/law-topic/data-protection/reform/rights-citizens_de"
                    target="_blank"
                  >
                    https://ec.europa.eu/info/law/law-topic/data-protection/reform/rights-citizens_de
                  </a>
                  .
                </li>
              </ol>
            </ol>
            <ol type={1} start={7} style={{ paddingBottom: '2em' }}>
              <li style={{ paddingBottom: '1em' }}>
                <strong>
                  Besteht eine Pflicht zur Bereitstellung von Daten?
                </strong>
              </li>
              <li style={{ listStyleType: 'none' }}>
                Im Rahmen der Erbringung unserer jeweiligen Leistungen ist es
                notwendig, dass Sie diejenigen personenbezogenen Daten
                bereitstellen, die für die Leistungserbringung erforderlich
                sind. Ohne diese Daten werden wir in der Regel nicht in der Lage
                sein, die entsprechende Leistung zu erbringen.
              </li>
            </ol>
            <ol type={1} start={8} style={{ paddingBottom: '2em' }}>
              <li style={{ paddingBottom: '1em' }}>
                <strong>
                  Gibt es eine automatisierte Entscheidungsfindung?
                </strong>
              </li>
              <li style={{ listStyleType: 'none' }}>
                Zur Erbringung unserer jeweiligen Leistungen nutzen wir keine
                automatisierte Entscheidungsfindung einschließlich Profiling
                gemäß Art. 22 DSGVO.
              </li>
            </ol>
            <p style={{ paddingBottom: '4em' }}>
              <i>Stand April 2020</i>
            </p>
          </li>
          <li id="cookie">
            <strong>Cookie Informationen</strong>
            <br />
            <br />
            <p style={{ paddingBottom: '2em' }}>
              Diese Cookie Informationen erläutern Ihnen, den Besuchern dieser
              Website, wie wir, die Interhyp AG, Cookies und ähnliche
              Technologien (gemeinsam, wenn nicht anders dargestellt:
              "Cookies"), mit denen eine Verarbeitung Ihrer personenbezogenen
              Daten erfolgt, einsetzen. Sie erfahren, was Cookies sind und
              welche Cookies wir zu welchen Zwecken und auf welcher
              Rechtsgrundlage nutzen. Außerdem informieren wir Sie in diesen
              Cookie Informationen darüber, wie lange Ihre personenbezogenen
              Daten im Zusammenhang mit der Nutzung von Cookies gespeichert
              werden und wie Sie die Cookies steuern können.
              <br />
              <br />
              Informationen zur Verarbeitung Ihrer personenbezogenen Daten durch
              die Interhyp AG außerhalb der Nutzung von Cookies finden Sie in
              unseren <a href="#1">Datenschutzinformationen</a>.
            </p>
            <ol type={1} start={1} style={{ paddingBottom: '2em' }}>
              <li style={{ paddingBottom: '1em' }}>
                <strong>Was sind Cookies?</strong>
              </li>
              <li style={{ listStyleType: 'none' }}>
                Cookies sind kleine Textdateien, die beim Besuch von Websites
                auf den Speichern Ihrer Endgeräte wie PCs, Smartphones oder
                Tablet Computern ("Endgerät") abgelegt werden. Cookies erlauben
                es einer Website, das Endgerät eines Besuchers bei einem
                wiederholten Besuch wiederzuerkennen. Dadurch helfen Cookies uns
                sicherzustellen, dass die Website ordnungsgemäß funktioniert und
                nachzuvollziehen, wie Sie die Website nutzen, damit wir unsere
                Website und Ihre Nutzererfahrung verbessern und die Website an
                Ihre Interessen anpassen können. Dies ist möglich, indem Cookies
                Ihre Vorlieben speichern oder Sie wiedererkennen, wenn Sie auf
                die Website zurückkehren.
              </li>
            </ol>
            <ol type={1} start={2} style={{ paddingBottom: '2em' }}>
              <li style={{ paddingBottom: '1em' }}>
                <strong>Welche Arten von Cookies verwenden wir?</strong>
              </li>
              <li style={{ listStyleType: 'none', paddingBottom: '2em' }}>
                Wir nutzen sowohl First-Party als auch Third-Party Cookies.
                First-Party Cookies werden von der Interhyp AG gesetzt und haben
                eine "Interhyp" Inhaberdomain. Third-Party Cookies hingegen
                werden von einem Drittanbieter oder einem externen Unternehmen
                auf der Website gespeichert und haben entsprechende Domains.
                <br />
                <br />
                Wir nutzen Session Cookies und Persistent Cookies: Session
                Cookies werden gelöscht, wenn Sie Ihren Browser schließen.
                Persistent Cookies bleiben auf Ihrem Endgerät gespeichert, bis
                Sie diese löschen oder bis ihre Lebensdauer abläuft.
                <br />
                <br />
                Wir verwenden auf der Website die folgenden Cookies:
              </li>
              <li style={{ listStyleType: 'none', width: '100%' }}>
                <div style={{ overflow: 'scroll', width: '100%' }}>
                  <table
                    cellSpacing={0}
                    cellPadding={10}
                    border={1}
                    style={{
                      width: '70em',
                      hyphens: 'auto',
                      wordBreak: 'break-word',
                    }}
                  >
                    <tbody>
                      <tr>
                        <td valign="top">
                          <strong>Bezeichnung/ Name des Cookies/ Tools</strong>
                        </td>
                        <td valign="top">
                          <strong>Anbieter (inkl. Adresse)</strong>
                        </td>
                        <td valign="top">
                          <strong>Zweck des Cookies</strong>
                        </td>
                        <td valign="top">
                          <strong>Opt-Out Link</strong>
                        </td>
                        <td valign="top">
                          <strong>Speicherdauer des Cookies</strong>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={5} valign="top">
                          <strong>
                            Technisch notwendige und funktionale Cookies
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td valign="top">Tealium Tag Manager</td>
                        <td valign="top">
                          Tealium
                          <br />
                          c/o Mindspace, Viktualienmarkt 8, 80331 München,
                          Deutschland
                        </td>
                        <td valign="top">
                          Tag-Management-System zum Verwalten von Tags
                        </td>
                        <td valign="top"></td>
                        <td valign="top">1 Jahr</td>
                      </tr>
                      <tr>
                        <td valign="top">Tealium Consent Manager</td>
                        <td valign="top">
                          Tealium
                          <br />
                          c/o Mindspace, Viktualienmarkt 8, 80331 München,
                          Deutschland
                        </td>
                        <td valign="top">
                          Consent-Management-Plattform, die die Zustimmung der
                          Nutzer zur Datenverarbeitung einholt
                        </td>
                        <td valign="top"></td>
                        <td valign="top">1 Jahr</td>
                      </tr>
                      <tr>
                        <td valign="top">Backoffice Cookies</td>
                        <td valign="top">
                          Interhyp AG
                          <br />
                          Domagkstraße 34, 80807 München, Deutschland
                        </td>
                        <td valign="top">
                          Zuordnung des Antrags zu einem Partner und/ oder
                          Werbemittel zum Zwecke der Vergütung
                        </td>
                        <td valign="top"></td>
                        <td valign="top">30 Tage</td>
                      </tr>
                      <tr>
                        <td valign="top">User-ID</td>
                        <td valign="top">
                          Interhyp AG
                          <br />
                          Domagkstraße 34, 80807 München, Deutschland
                        </td>
                        <td valign="top">
                          Identifikation des Nutzers zur Speicherung bereits
                          getätigter Eingaben
                        </td>
                        <td valign="top"></td>
                        <td valign="top">1 Jahr</td>
                      </tr>
                      <tr>
                        <td valign="top">Optimizely Webanalyse</td>
                        <td valign="top">
                          Optimizely, Inc.,
                          <br />
                          631 Howard Street, Suite 100 San Francisco, CA 94105,
                          USA
                        </td>
                        <td valign="top">
                          Durchführung von A/B-Test bzw. Ausspielung von
                          Personalisierungskampagnen zur Verbesserung des
                          Nutzererlebnisses
                        </td>
                        <td valign="top">
                          <a
                            href="https://interhyp.de/?optimizely_opt_out=true"
                            target="_blank"
                          >
                            https://interhyp.de/?optimizely_opt_out=true
                          </a>
                        </td>
                        <td valign="top">Max. 6 Monate</td>
                      </tr>
                      <tr>
                        <td valign="top">Webtrekk Report Tool</td>
                        <td valign="top">
                          Webtrekk GmbH
                          <br />
                          Robert-Koch-Platz 4, 10115 Berlin, Deutschland
                        </td>
                        <td valign="top">
                          Erfolgsberwertung und Optimierung der Website, durch
                          statisitische Auswertung der Online-Aktivitäten sowie
                          Nutzung der Website
                        </td>
                        <td valign="top">
                          <a
                            href="https://www.webtrekk.com/de/index/opt-out-webtrekk/abmeldung-vom-tracking"
                            target="_blank"
                          >
                            https://www.webtrekk.com/de/index/opt-out-webtrekk/abmeldung-vom-tracking
                          </a>
                        </td>
                        <td valign="top">6 Monate</td>
                      </tr>
                      <tr>
                        <td valign="top">Google Web Fonts</td>
                        <td valign="top">
                          Google Inc.,
                          <br />
                          1600 Amphitheatre Parkway, Mountain View, CA 94043,
                          USA
                        </td>
                        <td valign="top">
                          Verbesserung der optischen Darstellung der Texte auf
                          unserer Website
                        </td>
                        <td valign="top"></td>
                        <td valign="top">Keine Speicherung</td>
                      </tr>
                      <tr>
                        <td valign="top">MyFonts</td>
                        <td valign="top">
                          Monotype Imaging Holdings Inc.,
                          <br />
                          600 Unicorn Park Drive, Woburn, MA 01801 USA
                        </td>
                        <td valign="top">
                          Verbesserung des Gesamtbilds im Rahmen des
                          Markenauftritts durch Nutzung definierter Schriftarten
                        </td>
                        <td valign="top"></td>
                        <td valign="top">30 Tage</td>
                      </tr>
                      <tr>
                        <td valign="top">
                          QualityClick (Netslave, Partnerprogramm)
                        </td>
                        <td valign="top">
                          NetSlave GmbH,
                          <br />
                          Simon-Dach-Str. 12, 10245 Berlin, Deutschland
                        </td>
                        <td valign="top">
                          Korrekte Zuordnung des Erfolgs eines Werbemittels und
                          der entsprechenden Abrechnung im Rahmen des
                          Affiliate-Netzwerkes
                        </td>
                        <td valign="top">
                          <a
                            href="https://www.interhyp-partnerprogramm.de/cookie_optout.cgi"
                            target="_blank"
                          >
                            https://www.interhyp-partnerprogramm.de/cookie_optout.cgi
                          </a>
                        </td>
                        <td valign="top">30 Tage</td>
                      </tr>
                      <tr>
                        <td colSpan={5} valign="top">
                          <strong>Performance-Cookies</strong>
                        </td>
                      </tr>
                      <tr>
                        <td valign="top">
                          IntelliAd Webanalyse + Bid-Management
                        </td>
                        <td valign="top">
                          diva-e Products GmbH,
                          <br />
                          St. Martin Str. 78 (Gebäude BT3/EG), 81541 München,
                          Deutschland
                        </td>
                        <td valign="top">
                          Erfolgbewertung von Marketing-Kampagnen und -Kanälen
                        </td>
                        <td valign="top">
                          <a
                            href="https://login.intelliad.com/optout.php"
                            target="_blank"
                          >
                            https://login.intelliad.com/optout.php
                          </a>
                        </td>
                        <td valign="top">90 Tage</td>
                      </tr>
                      <tr>
                        <td valign="top">Adition adserving</td>
                        <td valign="top">
                          Adition technologies AG,
                          <br />
                          Oststraße 55, 40211 Düsseldorf, Deutschland
                        </td>
                        <td valign="top">
                          Kampagnenwirkungstracking auf unseren Websites
                        </td>
                        <td valign="top">
                          <a
                            href="https://www.adition.com/kontakt/datenschutz/"
                            target="_blank"
                          >
                            https://www.adition.com/kontakt/datenschutz/
                          </a>
                        </td>
                        <td valign="top">180 Tage</td>
                      </tr>
                      <tr>
                        <td colSpan={5} valign="top">
                          <strong>Personalisierungs-Cookies</strong>
                        </td>
                      </tr>
                      <tr>
                        <td valign="top">Google Ads</td>
                        <td valign="top">
                          Google Inc.,
                          <br />
                          1600 Amphitheatre Parkway, Mountain View, CA 94043,
                          USA
                        </td>
                        <td valign="top">
                          Optimierung der Website durch Identifikation des
                          Besucherveraltens abhängig von geklickter
                          Werbeanzeige.
                        </td>
                        <td valign="top">
                          <a
                            href="https://adssettings.google.com/authenticated"
                            target="_blank"
                          >
                            https://adssettings.google.com/authenticated
                          </a>
                        </td>
                        <td valign="top">90 Tage</td>
                      </tr>
                      <tr>
                        <td valign="top">Facebook Pixel</td>
                        <td valign="top">
                          Facebook Ireland Ltd.,
                          <br />4 Grand Canal Square, Grand Canal Harbour,
                          Dublin 2, Ireland
                        </td>
                        <td valign="top">
                          Darstellung gezielter, passender Werbung beim Besuch
                          von Facebook, Umsetzung von Retargetingmaßnahmen
                        </td>
                        <td valign="top">
                          <a
                            href="https://www.facebook.com/ads/preferences/"
                            target="_blank"
                          >
                            https://www.facebook.com/ads/preferences/
                          </a>
                        </td>
                        <td valign="top">180 Tage</td>
                      </tr>
                      <tr>
                        <td valign="top">Microsoft Ads (Bing Ads UET Tag)</td>
                        <td valign="top">
                          Microsoft Corporation,
                          <br />
                          One Microsoft Way, Redmond, WA 98052-6399, USA
                        </td>
                        <td valign="top">
                          Erfolgsbewertung getätigter Werbeaktionen durch die
                          Erstellung pseudonymisierter Nutzungsprofile
                        </td>
                        <td valign="top">
                          <a
                            href="https://account.microsoft.com/privacy/ad-settings/signedout"
                            target="_blank"
                          >
                            https://account.microsoft.com/privacy/ad-settings/signedout
                          </a>
                        </td>
                        <td valign="top">180 Tage</td>
                      </tr>
                      <tr>
                        <td valign="top">Adition adserving</td>
                        <td valign="top">
                          Adition technologies AG,
                          <br />
                          Oststraße 55, 40211 Düsseldorf, Deutschland
                        </td>
                        <td valign="top">
                          Aussteuerung nutzerbasierter Onlinewerbung
                        </td>
                        <td valign="top">
                          <a
                            href="https://www.adition.com/kontakt/datenschutz/"
                            target="_blank"
                          >
                            https://www.adition.com/kontakt/datenschutz/
                          </a>
                        </td>
                        <td valign="top">180 Tage</td>
                      </tr>
                      <tr>
                        <td valign="top">Geo-Lokalisierung</td>
                        <td valign="top">
                          MaxMind Inc.,
                          <br />
                          14 Spring Street, 3rd Floor, Waltham, MA 02451, USA
                        </td>
                        <td valign="top">
                          Anbieten von standortbasierten Diensten auf Basis der
                          Ermittlung des ungefähren Standorts
                        </td>
                        <td valign="top">
                          <a
                            href="https://www.interhyp.de/datenschutz/geoip-optout.html"
                            target="_blank"
                          >
                            https://www.interhyp.de/datenschutz/geoip-optout.html
                          </a>
                        </td>
                        <td valign="top">Sessionablauf</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </li>
            </ol>
            <ol type={1} start={3} style={{ paddingBottom: '2em' }}>
              <li style={{ paddingBottom: '1em' }}>
                <strong>
                  Welche Daten werden durch Cookies auf welcher Rechtsgrundlage
                  verarbeitet?
                </strong>
              </li>
              <li style={{ listStyleType: 'none' }}>
                Da wir ein Interesse daran haben unsere Website funktionstüchtig
                und sicher zur Verfügung zu stellen, werden für zwingend
                notwendige Cookies bestimmte Daten, wie z. B. welchen Browser
                und welche Browsereinstellungen Sie nutzen, auf Basis von Art. 6
                Abs.1 lit. f DSGVO (Interessenabwägung), verarbeitet. Dies
                entspricht auch Ihren Interessen, die Seite optimal nutzen zu
                können. Zudem können wir Sie mit Hilfe dieser Cookies nicht als
                Person identifizieren. Deshalb stehen Ihre Interessen der
                Verarbeitung nicht entgegenstehen.
                <br />
                <br />
                Für bestimmte Cookies erheben wir von Ihnen auch z. B. die
                Region, von der aus Sie unsere Website besuchen. Die Daten, die
                dieser Typ Cookie erhebt, sind grundsätzlich anonymisiert und
                lassen keine Rückschlüsse auf Ihre Aktivitäten auf anderen
                Websites zu. Wir verarbeiten diese Daten auf Basis von Art. 6
                Abs.1 lit. f DSGVO (Interessenabwägung), da wir ein Interesse
                daran haben, die Website für Sie optimal zur Verfügung zu
                stellen. Dies entspricht auch Ihren Interessen, die Seite
                bestmöglich nutzen zu können. Zudem können wir Sie mit Hilfe
                dieser Cookies nicht als Person identifizieren. Deshalb stehen
                Ihre Interessen der Verarbeitung nicht entgegenstehen.
                <br />
                <br />
                Wir erheben ferner Daten über das Nutzungsverhalten der
                Websitebesucher auf unserer Website, z. B. welche Unterseiten
                häufig aufgerufen werden oder wo Fehlermeldungen auftreten. Wir
                erheben und speichern diese Daten, um die Funktion der Website
                sowie die zur Verfügung stehenden Services für die Nutzer stetig
                zu verbessern.
                <br />
                <br />
                Manche Cookies erheben Daten über Ihre Besuchsgewohnheiten auf
                der Website. Wir nutzen diese Daten, um Ihnen für Sie
                interessante und relevante Werbung anbieten zu können. Die so
                erhobenen Daten nutzen wir außerdem, um die Häufigkeit, mit der
                Sie eine Anzeige sehen, zu beschränken. Diese Cookies "merken"
                sich Ihre Besuche der Website.
                <br />
                <br />
                Bei Ihrem ersten Besuch der Website erhalten Sie den Hinweis,
                dass wir Cookies verwenden. Setzen Sie Ihren Besuch auf unserer
                Website fort, erklären Sie sich mit dem Einsatz von Cookies
                einverstanden. Zudem besteht für uns für den Einsatz von Cookies
                ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f
                DSGVO. Ein berechtigtes Interesse ergibt sich für Analyse- und
                Auswertungszwecke aus dem Ziel die Websiteerfahrung für den
                Benutzer zu verbessern. Daneben ergibt sich ein berechtigtes
                Interesse aufgrund der mit dem Einsatz von Cookies verfolgten
                Marketinginteressen.
              </li>
            </ol>
            <ol type={1} start={4} style={{ paddingBottom: '2em' }}>
              <li style={{ paddingBottom: '1em' }}>
                <strong>Wie können Sie den Einsatz von Cookies steuern?</strong>
              </li>
              <li style={{ listStyleType: 'none' }}>
                Sie können den Einsatz von Cookies über die Einstellungen Ihres
                Browsers konfigurieren. Auf diese Weise können Cookies
                aktiviert, deaktiviert oder gelöscht werden. Anleitungen dazu,
                wie dies genau funktioniert, finden Sie in der Regel im
                Hilfebereich Ihres Browsers. Möglicherweise können Cookies, ohne
                die die Website nicht funktioniert, nicht deaktiviert werden.
                Auch kann es möglich sein, dass Sie bei Ihrem Besuch der Website
                Einstellungen und Eingaben wiederholt selbst vornehmen müssen,
                wenn Sie Cookies deaktiviert oder gelöscht haben.
              </li>
            </ol>
            <ol type={1} start={5} style={{ paddingBottom: '4em' }}>
              <li style={{ paddingBottom: '1em' }}>
                <strong>
                  An wen können Sie sich bei Fragen wenden? Wie erreichen Sie
                  den Datenschutzbeauftragten?
                </strong>
              </li>
              <li style={{ listStyleType: 'none' }}>
                In unseren <a href="#1">Datenschutzinformationen</a> finden Sie
                Informationen dazu, an wen Sie sich bei Fragen zu unseren Cookie
                Informationen wenden können.
              </li>
            </ol>
          </li>
          <li id={3}>
            <strong>Links auf Internetseiten Dritter</strong>
            <p style={{ paddingBottom: '4em' }}>
              Auf unseren Internetseiten finden Sie Links, die auf
              Internetseiten Dritter verweisen. Wir weisen Sie deshalb
              ausdrücklich darauf hin, dass wir auf den Inhalt und die
              Gestaltung der verlinkten Seiten keinen Einfluss haben. Die
              Interhyp AG übernimmt keine Verantwortung oder Haftung für diese
              mit der Interhyp AG nicht im Zusammenhang stehenden Richtlinien
              und Verfahren. Sollten auf verlinkten fremden Seiten Verstöße
              gegen Gesetze oder die guten Sitten enthalten sein, werden wir die
              Links zu diesen Seiten unverzüglich nach Kenntnisnahme dieser
              Verstöße aus unserer Internetpräsenz entfernen. Auf den Servern,
              zu denen die externen Links führen, kann es andere
              Datenschutzerklärungen geben. Um diese zu überprüfen, sollten Sie
              die jeweilige Homepage aufsuchen und sich bei Bedarf direkt an den
              jeweiligen Anbieter wenden.
            </p>
          </li>
          <li id={4}>
            <strong>Soziale Netzwerke, Plugins und Tools</strong>
            <br />
            <br />
            <ol type={1} start={1} style={{ paddingBottom: '2em' }}>
              <li style={{ paddingBottom: '1em' }}>
                <strong>Soziale Netzwerke</strong>
              </li>
              <li style={{ listStyleType: 'none' }}>
                Im Internetangebot der Interhyp AG sind zudem Browser-Plug-Ins
                und Links von sozialen Netzwerken (z.B. Facebook, Twitter)
                integriert. Diese sozialen Netzwerke werden ausschließlich von
                Dritten betrieben, die ihren Geschäftssitz teilweise außerhalb
                der EU oder des EWR haben - ein adäquates Datenschutzniveau gem.
                §§ 4b, 4c BDSG besteht daher unter Umständen nicht. Die
                Browser-Plug-Ins und Links sind in unseren Internetangeboten
                durch Logos oder sonstige Hinweise kenntlich gemacht. Beim
                Besuch des Internetangebots der Interhyp AG, das ein derartiges
                Browser-Plug-In beinhaltet, wird eine Verbindung zwischen Ihrem
                Rechner (Browser) und den Servern des jeweiligen sozialen
                Netzwerkes hergestellt. Hierdurch wird die Information, dass Sie
                die Website der Interhyp AG besucht haben, an das soziale
                Netzwerk weitergeleitet. Der Besuch unseres Internetangebots
                wird dann, falls Sie über Ihr persönliches Benutzerkonto beim
                sozialen Netzwerk eingeloggt sind oder sich während des Besuchs
                unseres Internetangebots einloggen, Ihrem Konto zugeordnet.
                Durch die Interaktion mit Browser-Plug-Ins oder Links, z.B.
                durch Betätigen eines "Gefällt mir"-Buttons oder Hinterlassen
                eines Kommentars werden diese Informationen an das jeweilige
                soziale Netzwerk übermittelt und dort gespeichert. Diese
                Datenübermittlung lässt sich nur unterbinden, indem Sie sich vor
                dem Besuch unseres Internetangebots unter Ihrem Account (beim
                sozialen Netzwerk) ausloggen. Zweck und Umfang der Datenerhebung
                durch soziale Netzwerke sowie die dortige weitere Verarbeitung
                und Nutzung Ihrer Daten wie auch Ihre diesbezüglichen Rechte und
                Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre
                entnehmen Sie bitte den jeweiligen Datenschutzhinweisen der
                jeweiligen Betreiber der sozialen Netzwerke.
              </li>
            </ol>
            <ol type={1} start={2} style={{ paddingBottom: '2em' }}>
              <li style={{ paddingBottom: '1em' }}>
                <strong>YouTube</strong>
              </li>
              <li style={{ listStyleType: 'none' }}>
                Auf unserer Website sind YouTube-Videos eingebunden, die direkt
                von unserer Seite aus abspielbar sind. Dabei werden Plugins der
                von Google betriebenen Seite YouTube genutzt. Betreiber der
                Seiten ist die YouTube, LLC, 901 Cherry Ave., San Bruno, CA
                94066, USA.
                <br />
                <br />
                Durch den Besuch einer unserer mit einem YouTube-Plugin
                ausgestatteten Seiten, wird eine Verbindung zu den Servern von
                YouTube hergestellt. Dabei wird dem YouTube-Server mitgeteilt,
                welche unserer Seiten Sie besucht haben. Diese Mitteilung
                erfolgt unabhängig davon, ob Sie ein Nutzerkonto bei YouTube
                besitzen und eingeloggt sind, oder nicht. Wenn Sie über Ihr
                persönliches YouTube-Konto eingeloggt sind, wird YouTube eine
                Zuordnung Ihres Surfverhaltens zu Ihrem persönlichen Profil
                ermöglicht. Sollten Sie solch eine Zuordnung nicht wünschen,
                müssen Sie sich aus Ihrem Konto ausloggen.
                <br />
                <br />
                Die Einbindung von YouTube-Videos auf unserer Website erfolgt im
                Interesse einer ansprechenden Darstellung unserer
                Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne
                von Art. 6 Abs. 1 lit. f DSGVO dar.
                <br />
                <br />
                Zusätzliche Informationen zum Umfang und Zweck der Datenerhebung
                sowie zur Verarbeitung durch YouTube finden Sie in der
                Datenschutzerklärung von YouTube:{' '}
                <a
                  href="https://www.google.de/intl/de/policies/privacy"
                  target="_blank"
                >
                  https://www.google.de/intl/de/policies/privacy
                </a>
                .
              </li>
            </ol>
            <ol type={1} start={3} style={{ paddingBottom: '4em' }}>
              <li style={{ paddingBottom: '1em' }}>
                <strong>OpenStreetMap</strong>
              </li>
              <li style={{ listStyleType: 'none' }}>
                Wir binden auf unserer Website Kartenmaterial von OpenStreetMap
                ein. Bei OpenStreetMap handelt es sich um ein Projekt der
                OpenStreetMap Foundation, 132 Maney Hill Road, Sutton Coldfield,
                West Midlands B72 1JU, Großbritannien (
                <a href="https://www.openstreetmap.de/" target="_blank">
                  https://www.openstreetmap.de/
                </a>
                ), das frei nutzbare Geodaten sammelt und in einer Datenbank zur
                freien Nutzung vorhält.
                <br />
                <br />
                Zur Nutzung der Funktionen von OpenStreetMap ist es notwendig,
                Ihre IP Adresse zu speichern. Diese Informationen werden in der
                Regel an einen Server von OpenStreetMap übertragen und dort
                gespeichert. Der Anbieter dieser Seite hat keinen Einfluss auf
                diese Datenübertragung. Die Nutzung von OpenStreetMap erfolgt im
                Interesse einer ansprechenden Darstellung unserer
                Online-Angebote und an einer leichten Auffindbarkeit der von uns
                auf der Website angegebenen Orte. Dies stellt ein berechtigtes
                Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Mehr
                Informationen zum Umgang mit Nutzerdaten finden Sie auf der
                Datenschutzseite von OpenStreetMaps{' '}
                <a
                  href="https://wiki.openstreetmap.org/wiki/DE:Datenschutz"
                  target="_blank"
                >
                  https://wiki.openstreetmap.org/wiki/DE:Datenschutz
                </a>{' '}
                und hier{' '}
                <a
                  href="http://wiki.openstreetmap.org/wiki/Legal_FAQ"
                  target="_blank"
                >
                  http://wiki.openstreetmap.org/wiki/Legal_FAQ
                </a>
                .
              </li>
            </ol>
          </li>
          <li id={5}>
            <strong>Aktualisierung dieser Datenschutzerklärung</strong>
            <br />
            <br />
            <p>
              Diese Datenschutzerklärung wird regelmäßig ergänzt und
              aktualisiert, sofern sich rechtliche Bestimmungen ändern, die
              Interhyp AG neue Produkte oder Dienstleistungen einführt oder
              Veränderungen der EDV- und Internettechnik vorgenommen werden. Die
              Änderungen werden wir an dieser Stelle veröffentlichen.
            </p>
          </li>
        </ol>
      </div>
    </Content>
  </Layout>
)

export default Datenschutz
