import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import Logo from '../../images/logo.svg'
import media from '../../styles/media'
import Button from '../Buttons'
import Footer from '../Footer'

const Header = styled.div`
  min-height: 12.8rem;
  display: flex;
  align-items: center;
  padding-left: 8.5rem;
  ${media.down.md`
    min-height: 8rem;
    padding-left: 1.5rem;
  `}
`

const Body = styled.div`
  padding: 13.6rem 1.5rem 4.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${props => props.theme.colors.secondary};
  padding-top: 4.8rem;
  ${media.down.md`
		`}
`

const Content = styled.div`
  max-width: 92rem;
  padding: 7.2rem 9.5rem 8.8rem 9.5rem;
  background-color: white;
  margin-top: 3.2rem;
  ${media.down.md`
		padding: 4.8rem 1.5rem;
    `}
  ${media.down.lg`
		max-width: 100%;
  `}
`

const StyledButton = styled(Button)`
  ${({ noMargin }) =>
    !noMargin &&
    css`
      margin-top: 3.2rem;
    `}
`

const CleanLayout = ({ children }) => (
  <React.Fragment>
    <Header>
      <Link to="/">
        <img src={Logo} alt="Logo"></img>
      </Link>
    </Header>
    <Body>
      <Link to="/">
        <StyledButton noMargin primary>
          Zurück zur Seite
        </StyledButton>
      </Link>
      <Content>{children}</Content>
      <Link to="/">
        <StyledButton primary>Zurück zur Seite</StyledButton>
      </Link>
    </Body>
    <Footer></Footer>
  </React.Fragment>
)

export default CleanLayout
