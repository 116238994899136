import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { space } from 'styled-system'
import media from '../../styles/media'

const StyledButton = styled.button`
  cursor: pointer;
  font-weight: bold;
  font-family: ${props => props.theme.fontFamily};
  position: relative;
  padding: 0;
  margin: 0;
  width: auto;
  overflow: visible;
  border: none;
  white-space: nowrap;

  appearance: none;

  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  -webkit-appearance: none;
  outline: none;

  color: white;
  background-color: ${props =>
    props.highlight
      ? props.theme.colors.highlight
      : props.theme.colors.primary};
  min-height: 5.6rem;
  font-size: 1.4rem;
  line-height: 2.2rem;
  padding: 0 2.6rem;
  transition: background-color 0.2s ease-in-out;
  &:hover {
    background-color: #BA6B36;
  }
  ${media.up.sm`
    font-size: 1.6rem;
    line-height: 2.6rem;
  `}

  ${props => {
    if (props.outlined) {
      return `
        background-color: white;
        color: ${
          props.highlight
            ? props.theme.colors.highlight
            : props.theme.colors.primary
        };
        border: 2px solid ${
          props.highlight
            ? props.theme.colors.highlight
            : props.theme.colors.primary
        };
      `
    }
    if (props.block) {
      return `
        width: 100%;
      `
    }
  }}
  ${space}
`

const Button = ({ children, target, href, onClick, ...rest }) => (
  <>
    {href ? (
      <a href={href} target={target} onClick={onClick}>
        <StyledButton {...rest}>{children}</StyledButton>
      </a>
    ) : (
      <StyledButton onClick={onClick} {...rest}>
        {children}
      </StyledButton>
    )}
  </>
)

Button.propTypes = {
  outlined: PropTypes.bool,
}

export default Button
